
// 3rd party styles
@import 'bootstrap/scss/bootstrap';
@import 'bootstrap-icons/font/bootstrap-icons.css';
@import 'izitoast/dist/css/iziToast.min.css';

// Global styles
@import './_common.scss';

// Specific styles
@import './_styles/home.scss';
@import './_styles/home-dark.scss';
@import './_styles/sudoku.scss';